<template>
  <div class="layout-spacing">
    <espece
      v-for="(e, i) in especes"
      :key="i"
      :espece="e"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Espece from './espece.vue'
export default {
    components: {Espece},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            especes: 'identification/activeEspeces'
        })
    }
}
</script>

<style>

</style>