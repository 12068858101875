<template>
  <div class="dashboard">
    <div class="row layout-top-spacing">
      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
        <effectif />
        <espece-effectif />
        <medicament />
        <consultation />
      </div>
      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
        <utilisateurs v-if="is_super_admin" />
        <client />
      </div>
      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
        <epidemiologie />
        <chirurgie />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Effectif from '../components/dashboard/effectif.vue'
import EspeceEffectif from '../components/dashboard/especeEffectif.vue'
import Utilisateurs from '../components/dashboard/typeUser.vue'
import Client from '../components/dashboard/clients.vue'
import Epidemiologie from '../components/dashboard/epidemiologies.vue'
import Consultation from '../components/dashboard/consultation.vue'
import Medicament from '../components/dashboard/medicaments.vue'
import Chirurgie from '../components/dashboard/chirurgie.vue'
export default {
  name: 'Home',
  components: { Utilisateurs, Effectif, EspeceEffectif, Client, Epidemiologie, Consultation, Medicament, Chirurgie },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      is_super_admin: 'auth/is_super_admin'
    }),
    
  }
}
</script>
<style lang="scss" scoped>
 .dashboard{
   padding: 20px;
 }
</style>
