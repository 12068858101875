<template>
  <div class="sales-summary-content d-flex mb-2 mt-2">
    <div class="sales-summary-icon mr-3">
      <i
        class="las la-chart-bar sales-icon"
        :class="'sales-'+color+'-icon'"
      />
    </div>
    <div class="sales-progress flex-grow-1">
      <span class="font-14">{{ type.libelle }} ({{ pourcentage }}%)</span>
      <span class="font-12 float-right">{{ analyseType.length }}/{{ analyses.length }}</span>
      <div class="progress progress-sm">
        <div
          class="progress-bar"
          :class="'bg-gradient-'+color"
          role="progressbar"
          :aria-valuenow="pourcentage"
          :style="styleProgress"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props:{
        type: {type: Object, required: true},
        color: {type: String, default: 'primary'}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses'
        }),
        analyseType(){
            return [...this.analyses].filter(item => item.type === this.type.uid)
        },
        pourcentage(){
            if(this.analyseType.length > 0) return (this.analyseType.length * 100 / this.analyses.length).toFixed(2)
            return 0
        },
        styleProgress(){
            return {
                "width": this.pourcentage+'%'
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.sales-icon{
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 20px;
}
.sales-danger-icon{
    background: #e7515a;
    box-shadow: 0px 0px 9px 0px #e7515a;

}
.sales-secondary-icon{
    background:#5c1ac3;
    box-shadow: 0px 0px 9px 0px #5c1ac3;
}
</style>